exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contentful-bird-category-slug-js": () => import("./../../../src/pages/{ContentfulBirdCategory.slug}.js" /* webpackChunkName: "component---src-pages-contentful-bird-category-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-birds-js": () => import("./../../../src/templates/birds.js" /* webpackChunkName: "component---src-templates-birds-js" */),
  "component---src-templates-subcategory-birds-js": () => import("./../../../src/templates/subcategory-birds.js" /* webpackChunkName: "component---src-templates-subcategory-birds-js" */),
  "slice---src-components-footer-footer-tsx": () => import("./../../../src/components/footer/footer.tsx" /* webpackChunkName: "slice---src-components-footer-footer-tsx" */),
  "slice---src-components-header-header-tsx": () => import("./../../../src/components/header/header.tsx" /* webpackChunkName: "slice---src-components-header-header-tsx" */)
}

